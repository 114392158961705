<template>
  <div class="column is-narrow-mobile">
    <div class="card">
      <header class="card-header is-flex is-flex--space-between">
        <div>
          <p class="card-header-title is-size-5 has-text-grey">{{ $t('exchangeRates.title') }}</p>
        </div>
        <div class="is-flex is-flex--center">
          <div class="is-flex is-flex--space-between">
            <app-date-picker v-model="selected_date" v-bind:nearbyselectable="true"></app-date-picker>
          </div>
          <a class="card-header-icon" aria-label="more options">
            <router-link
              :disabled="!this.hasPermission('exchange:update')"
              :event="this.hasPermission('exchange:update') ? 'click' : ''"
              :to="{ name: 'createExchangeRate', query: { date: dateSelected } }"
              class="button is-accent is-small"
            >
              <span class="icon">
                <i class="fas fa-plus"></i>
              </span>
              <span>{{ $t('exchangeRates.add.rate') }}</span>
            </router-link>
          </a>
        </div>
      </header>
      <div class="card-content">
        <b-table :loading="requestExchangeRates" :data="exchangeRates" narrowed striped>
          <template slot-scope="props">
            <b-table-column field="id" label="ID" width="20" numeric>{{ props.row.id }}</b-table-column>
            <b-table-column field="country" label="Country" width="80" centered>
              <p>{{ props.row.country_code.name }}({{ props.row.country_code.code }})</p>
            </b-table-column>
            <b-table-column field="baseCountry" label="Base Country" width="80" centered>
              <p>{{ props.row.country_code_base.name }}({{ props.row.country_code_base.code }})</p>
            </b-table-column>

            <b-table-column field="rate" label="Rate" width="50" centered>
              <div v-if="isEditing && isEditing === props.row.id" style="display: flex; width: 120px; margin: 0px 30%">
                <b-field :type="editedRateError ? 'is-danger' : ''" style="height: 25px">
                  <currency-input
                    v-model="editedRate"
                    :defaultValue="editedRate"
                    :options="{ currencyDisplay: 'hidden' }"
                    :placeholder="'Rate'"
                    style="width: 100px"
                  ></currency-input>
                </b-field>
                <b-button
                  :disabled="editedRate ? editedRate.toString() === props.row.rate.toString() : true"
                  :loading="requestEditExchangeRate"
                  style="height: 36px; width: 25px; margin: 0px 2px"
                  class="button is-accent"
                  @click="handleSaveEdit()"
                  ><span class="icon">
                    <i class="fas fa-check"></i>
                  </span>
                </b-button>
                <b-button style="height: 36px; width: 25px; margin: 0px 2px" @click="handleCancelEdit()"
                  ><span class="icon">
                    <i class="fas fa-plus" style="rotate: 45deg"></i>
                  </span>
                </b-button>
              </div>
              <p v-else>{{ props.row.rate }}</p>
            </b-table-column>

            <b-table-column field="date" label="Date" width="70" centered>
              <span class="tag is-info">{{ formatDate(props.row.date.split('T')[0]) }}</span>
            </b-table-column>
            <b-table-column field="actions" label="Actions" width="20" centered>
              <a
                :style="{ opacity: !hasPermission('exchange:update') ? '0.5' : '1' }"
                class="has-text-info"
                @click="hasPermission('exchange:update') ? handleEditRow(props.row) : ''"
              >
                <b-tooltip :label="$t('tables.edit')" position="is-top" type="is-info" size="is-small" animated square>
                  <b-icon class="has-padding" pack="fas" icon="edit" size="is-small"></b-icon>
                </b-tooltip>
              </a>
              <a
                :style="{ opacity: !hasPermission('exchange:delete') ? '0.5' : '1' }"
                class="has-text-danger"
                @click="hasPermission('exchange:delete') ? showConfirmationModal(props.row.id, true) : ''"
              >
                <b-tooltip :label="$t('tables.remove')" position="is-top" type="is-danger" size="is-small" animated square>
                  <b-icon class="has-padding" pack="fas" icon="trash" size="is-small"></b-icon>
                </b-tooltip>
              </a>
            </b-table-column>
          </template>
          <template slot="empty">
            <section class="section">
              <div class="content has-text-grey has-text-centered">
                <p v-show="!requestExchangeRatesError && !requestExchangeRates">
                  <b-icon pack="fas" icon="exclamation" size="is-large"></b-icon>
                </p>
                <p v-show="!requestExchangeRatesError && !requestExchangeRates">{{ $t('warnings.ratesNoData') }}</p>
              </div>
              <div v-show="requestExchangeRatesError" class="content has-text-danger has-text-centered">
                <p>
                  <span class="icon is-large">
                    <i class="far fa-frown fa-3x"></i>
                  </span>
                </p>
                <p>{{ $t('errors.requestError') }}</p>
              </div>
            </section>
          </template>
        </b-table>
      </div>

      <b-modal :active.sync="confirmationModalActive" :canCancel="['x', 'outside']" class="has-modal-card">
        <app-modal-template ref="confirmationModal" :has-closable-button="true">
          <template slot="title">
            <p class="modal-card-title">Borrar exchange rate Id: {{ this.exchangeId }}</p>
          </template>
          <template slot="content">
            <p class="modal-card-title">¿Esta seguro que quiere borrar este exchange rate?</p>
          </template>
          <template slot="buttons">
            <button class="button is-accent" @click="onRemoveRate">
              <span class="icon">
                <i class="fa fa-trash"></i>
              </span>
              <span>Borrar</span>
            </button>
          </template>
        </app-modal-template>
      </b-modal>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import { formMixin } from '@/mixins/formMixin'
import DatePicker from '@/common/widgets/date-picker/DatePicker'
import ModalTemplate from '@/common/widgets/modal/ModalTemplate'
import CurrencyInput from '@/common/widgets/currency-input/CurrencyInput'
import moment from 'moment'
import utils from '../../utils/user-utils'

export default {
  name: 'exchangeRateTable',
  components: {
    'app-date-picker': DatePicker,
    appModalTemplate: ModalTemplate,
    'currency-input': CurrencyInput
  },
  mixins: [formMixin],
  data() {
    return {
      isEditing: undefined,
      editedRate: null,
      editedRateObj: null,
      editedRateError: false,
      selected_date: null,
      dateSelected: null,
      exchangeId: null,
      confirmationModalActive: false,
      removeOperation: null
    }
  },
  computed: {
    ...mapGetters([
      'exchangeRates',
      'requestExchangeRates',
      'requestExchangeRatesError',
      'requestEditExchangeRate',
      'successEditExchangeRate',
      'tokenValid'
    ])
  },
  watch: {
    tokenValid(value) {
      if (!value) {
        this.$router.replace({ name: 'login' })
      }
    },
    successEditExchangeRate() {
      this.handleCancelEdit()
      this.getExchangeRates({ q: this.dateSelected })
    },
    selected_date(v, oldV) {
      const stringDate = this.formatDate(v)
      this.$router.replace({ query: { date: stringDate } })
      this.searchFilteredRates({ dateSelected: stringDate })
    }
  },

  mounted() {
    const today = moment(new Date()).toDate()
    if (!this.$route.query.hasOwnProperty('date')) {
      this.selected_date = today
    } else {
      const date = moment(this.$route.query.date, 'DD/MM/YYYY').toDate()
      if (date > today) {
        this.selected_date = today
      } else {
        this.selected_date = date
      }
    }
  },
  methods: {
    ...mapActions(['getExchangeRates', 'editExchangeRate', 'removeRate']),
    searchFilteredRates({ dateSelected }) {
      this.$router.replace({ query: { date: dateSelected } })
      this.dateSelected = dateSelected
      this.getExchangeRates({ q: dateSelected })
    },
    formatDate(value) {
      if (value) {
        return moment(value).format('DD/MM/YYYY')
      }
    },
    hasPermission(key) {
      let permission = utils.hasPermission(key)
      return permission
    },
    handleEditRow(rate) {
      if (!this.isEditing) {
        this.isEditing = rate.id
        this.editedRate = rate.rate
        this.editedRateObj = rate
        this.editedRateError = false
      } else {
        this.isEditing = undefined
        this.editedRate = null
        this.editedRateObj = null
      }
    },
    handleCancelEdit() {
      this.isEditing = undefined
      this.editedRate = null
      this.editedRateObj = null
    },
    handleSaveEdit() {
      let rate
      rate = {
        ...this.editedRateObj,
        rate: this.editedRate.toString(),
        date: this.formatDate(this.editedRateObj.date.split('T')[0])
      }
      if (this.editedRate[0] === '' || this.editedRate[0] === '0') {
        this.editedRateError = true
      } else {
        this.editedRateError = false
        this.editExchangeRate(rate)
      }
    },
    showConfirmationModal(id, removeOperation) {
      this.confirmationModalActive = !this.confirmationModalActive
      this.exchangeId = id
      this.removeOperation = removeOperation
    },
    async onRemoveRate() {
      if (this.confirmationModalActive) {
        await this.removeRate(this.exchangeId)
      }
      this.closeModal()
    },
    closeModal() {
      this.$refs.confirmationModal.closeModal()
      this.getExchangeRates({ q: this.dateSelected })
    }
  }
}
</script>
<style lang="scss" scoped></style>
