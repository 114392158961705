<template>
  <div class="modal-card">
    <header class="modal-card-head">
      <p class="modal-card-title">
        <slot name="title"></slot>
      </p>
    </header>
    <section class="modal-card-body">
      <!-- <div class="columns">
        <div class="column">
          <slot name="stepper"></slot>
        </div>
      </div>-->
      <div class="section is-paddingless">
        <div class="columns is-centererd">
          <div class="column">
            <slot name="content"></slot>
          </div>
        </div>
      </div>
    </section>
    <footer class="modal-card-foot is-flex is-flex--end">
      <div class="buttons">
        <button v-if="hasClosableButton" class="button" @click="closeModal">
          <span>Cancelar</span>
        </button>
        <slot name="buttons"></slot>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: 'modalTemplate',
  props: {
    hasClosableButton: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {}
  },
  methods: {
    closeModal() {
      this.$parent.close()
      // alert('hey')
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
