import { render, staticRenderFns } from "./ModalTemplate.vue?vue&type=template&id=1704844c&scoped=true&"
import script from "./ModalTemplate.vue?vue&type=script&lang=js&"
export * from "./ModalTemplate.vue?vue&type=script&lang=js&"
import style0 from "./ModalTemplate.vue?vue&type=style&index=0&id=1704844c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1704844c",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/work/code/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!module.hot.data) {
      api.createRecord('1704844c', component.options)
    } else {
      api.reload('1704844c', component.options)
    }
    module.hot.accept("./ModalTemplate.vue?vue&type=template&id=1704844c&scoped=true&", function () {
      api.rerender('1704844c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/common/widgets/modal/ModalTemplate.vue"
export default component.exports