var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "modal-card" }, [
    _c("header", { staticClass: "modal-card-head" }, [
      _c("p", { staticClass: "modal-card-title" }, [_vm._t("title")], 2)
    ]),
    _c("section", { staticClass: "modal-card-body" }, [
      _c("div", { staticClass: "section is-paddingless" }, [
        _c("div", { staticClass: "columns is-centererd" }, [
          _c("div", { staticClass: "column" }, [_vm._t("content")], 2)
        ])
      ])
    ]),
    _c("footer", { staticClass: "modal-card-foot is-flex is-flex--end" }, [
      _c(
        "div",
        { staticClass: "buttons" },
        [
          _vm.hasClosableButton
            ? _c(
                "button",
                { staticClass: "button", on: { click: _vm.closeModal } },
                [_c("span", [_vm._v("Cancelar")])]
              )
            : _vm._e(),
          _vm._t("buttons")
        ],
        2
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }